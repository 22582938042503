<template>
    <div class="login-form-container">

        <div class="">
            <p class="title-font primary_font--text mb-1 font-weight-black">{{ $t('forms.LoginForm.welcome.sign_in') }}</p>
            <p>{{ $t('forms.LoginForm.welcome.welcome') }}</p>
        </div>

        <v-alert class="mb-5" type="error" text outlined v-if="validationError">
            {{ $t('views.LoginView.login_error') }}
        </v-alert>

        <LoginForm ref="form" :loading.sync="loading" :validationError.sync="validationError" :isFormValid.sync="isFormValid" @login-completed="onLoginCompleted">
            <template #submit>
                <v-btn block height="42px" elevation="0" :disabled="!isFormValid" :loading="loading" type="submit" :color="isLightTheme ? 'indigo accent-4 white--text' : 'primary'" class="mb-9 font-weight-bold">{{ $t('actions.login') }}</v-btn>

                <div class="text-center">
                    {{ $t('views.LoginView.register_label') }}
                    <router-link :to="{ name: 'register', query: $route.query }"  class="mb-3 primary--text">{{ $t('actions.register') }}</router-link>
                </div>

               <GoBack class="mt-8 mb-4" goBack="login" /> 
                
            </template>
        </LoginForm>
    </div>
</template>
<script>
import LoginForm from '@/components/forms/LoginForm'
import RateColor from '@/shared/mixins/RateColor'
import GoBack from './GoBack'

export default {
    name: 'EmailLogin',
    mixins: [ RateColor ],
    data: vm => ({
        loading         : false,
        isFormValid     : true,
        validationError : false,
        shouldShowAlerts: false,
    }),
    mounted() {
        // Depois de 5s os alertas somem
        setTimeout(() => {
            this.shouldShowAlerts = false
        }, 5000)

        this.$emit('hideWelcomeBanner')
    },
    methods: {
        onLoginCompleted() {
            this.$router.push(this.computedRedirect)
        },
    },
    computed: {
        computedRedirect() {
            let defaultRoute = { name: 'home' }
            return this.$lodash.get(this.$route, 'query.redirect', defaultRoute)
        },
    },
    components: { LoginForm, GoBack },
}
</script>

<style lang="scss" scoped>
.login-form-container {
    max-width: 300px;
    margin: auto;
}

a {
    text-decoration: none;
}

.title-font{
    font-size: 1.75rem;
}
</style>
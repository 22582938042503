var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-form-container" },
    [
      _c("div", {}, [
        _c(
          "p",
          {
            staticClass: "title-font primary_font--text mb-1 font-weight-black"
          },
          [_vm._v(_vm._s(_vm.$t("forms.LoginForm.welcome.sign_in")))]
        ),
        _c("p", [_vm._v(_vm._s(_vm.$t("forms.LoginForm.welcome.welcome")))])
      ]),
      _vm.validationError
        ? _c(
            "v-alert",
            {
              staticClass: "mb-5",
              attrs: { type: "error", text: "", outlined: "" }
            },
            [_vm._v(" " + _vm._s(_vm.$t("views.LoginView.login_error")) + " ")]
          )
        : _vm._e(),
      _c("LoginForm", {
        ref: "form",
        attrs: {
          loading: _vm.loading,
          validationError: _vm.validationError,
          isFormValid: _vm.isFormValid
        },
        on: {
          "update:loading": function($event) {
            _vm.loading = $event
          },
          "update:validationError": function($event) {
            _vm.validationError = $event
          },
          "update:validation-error": function($event) {
            _vm.validationError = $event
          },
          "update:isFormValid": function($event) {
            _vm.isFormValid = $event
          },
          "update:is-form-valid": function($event) {
            _vm.isFormValid = $event
          },
          "login-completed": _vm.onLoginCompleted
        },
        scopedSlots: _vm._u([
          {
            key: "submit",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "mb-9 font-weight-bold",
                    attrs: {
                      block: "",
                      height: "42px",
                      elevation: "0",
                      disabled: !_vm.isFormValid,
                      loading: _vm.loading,
                      type: "submit",
                      color: _vm.isLightTheme
                        ? "indigo accent-4 white--text"
                        : "primary"
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("actions.login")))]
                ),
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("views.LoginView.register_label")) +
                        " "
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "mb-3 primary--text",
                        attrs: {
                          to: { name: "register", query: _vm.$route.query }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("actions.register")))]
                    )
                  ],
                  1
                ),
                _c("GoBack", {
                  staticClass: "mt-8 mb-4",
                  attrs: { goBack: "login" }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
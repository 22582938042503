var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValid,
        callback: function($$v) {
          _vm.isFormValid = $$v
        },
        expression: "isFormValid"
      }
    },
    [
      _c("cp-text-field", {
        staticClass: "mb-n1",
        attrs: {
          autofocus: "",
          type: "identifier",
          label: _vm.$t("forms.LoginForm.identifier.label"),
          hint: _vm.$t("forms.LoginForm.identifier.hint"),
          placeholder: _vm.$t("forms.LoginForm.identifier.placeholder"),
          disabled: _vm.loading,
          rules: _vm.rules.identifier,
          required: ""
        },
        model: {
          value: _vm.formData.identifier,
          callback: function($$v) {
            _vm.$set(_vm.formData, "identifier", $$v)
          },
          expression: "formData.identifier"
        }
      }),
      _c("cp-text-field", {
        staticClass: "mt-n1 ",
        attrs: {
          type: "password",
          label: _vm.$t("forms.LoginForm.password.label"),
          hint: _vm.$t("forms.LoginForm.password.hint"),
          placeholder: _vm.$t("forms.LoginForm.password.placeholder"),
          disabled: _vm.loading,
          rules: _vm.rules.password,
          required: ""
        },
        on: {
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.submit($event)
          }
        },
        model: {
          value: _vm.formData.password,
          callback: function($$v) {
            _vm.$set(_vm.formData, "password", $$v)
          },
          expression: "formData.password"
        }
      }),
      _c(
        "span",
        { staticClass: "d-flex justify-end mb-3" },
        [
          _c(
            "router-link",
            {
              staticClass: "caption-font-size forgot-password",
              class: _vm.isLightTheme ? "router-link-default" : "",
              attrs: { to: { name: "auth.forgot_password" } }
            },
            [_vm._v(_vm._s(_vm.$t("forms.LoginForm.forgot_password")))]
          )
        ],
        1
      ),
      _c("div", { staticClass: "clear-fix" }),
      _vm._t("default"),
      _vm._t("submit"),
      _c("AlertModal", { ref: "alert" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="text-center d-flex justify-center align-center">
        <router-link class="primary_font--text" :to="{ name: goBack, query: $route.query }">
            <v-icon size="12" class="mr-1">fas fa-arrow-left</v-icon>
            <span class="secondary_font--text">{{ $t('actions.back') }}</span>
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        goBack: {
            type: String,
            required: true,
        }
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
}
</style>

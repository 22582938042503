var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center d-flex justify-center align-center" },
    [
      _c(
        "router-link",
        {
          staticClass: "primary_font--text",
          attrs: { to: { name: _vm.goBack, query: _vm.$route.query } }
        },
        [
          _c("v-icon", { staticClass: "mr-1", attrs: { size: "12" } }, [
            _vm._v("fas fa-arrow-left")
          ]),
          _c("span", { staticClass: "secondary_font--text" }, [
            _vm._v(_vm._s(_vm.$t("actions.back")))
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
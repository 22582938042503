<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValid">
        <cp-text-field
            class="mb-n1"
            autofocus
            v-model="formData.identifier"
            type="identifier"
            :label="$t('forms.LoginForm.identifier.label')"
            :hint="$t('forms.LoginForm.identifier.hint')"
            :placeholder="$t('forms.LoginForm.identifier.placeholder')"
            :disabled="loading"
            :rules="rules.identifier"
            required
        />

        <cp-text-field
            class="mt-n1 "
            v-model="formData.password"
            type="password"
            :label="$t('forms.LoginForm.password.label')"
            :hint="$t('forms.LoginForm.password.hint')"
            :placeholder="$t('forms.LoginForm.password.placeholder')"
            @keyup.enter="submit"
            :disabled="loading"
            :rules="rules.password"
            required
        />

        <span class="d-flex justify-end mb-3">
            <router-link :class="isLightTheme ? 'router-link-default' :''" class="caption-font-size forgot-password" :to="{ name: 'auth.forgot_password' }">{{ $t('forms.LoginForm.forgot_password') }}</router-link>
        </span>
        <div class="clear-fix"></div>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

        <AlertModal ref="alert"/>
    </v-form>
</template>

<script>
import HasErrorHandlerMixin        from '@/mixins/HasErrorHandlerMixin'
import RateColor                   from '@/shared/mixins/RateColor'
import AlertModal                  from '@/components/modals/AlertModal'
import { required, length } from '@/utils/validation'

export default {
    name: 'LoginForm',
    mixins: [ HasErrorHandlerMixin, RateColor ],
    props: {
        disabled: Boolean,
    },
    data: vm => ({
        formData: {
            identifier: '',
            password  : '',
        },
        loading        : false,
        validationError: false,
        isFormValid    : true,
        rules          : {
            identifier: [required.and(length(0, 191))],
            password  : [required.and(length(6, 30))],
        },
    }),
    components: { AlertModal },
    methods: {
        async submit() {
            this.validationError = false

            let isValid = this.$refs.form.validate()

            if (!isValid || !this.isFormValid) {
                this.$emit('update:isFormValid', this.isFormValid)
                return
            }

            this.formData.identifier = this.formData.identifier.toLowerCase().trim()

            this.loading = true
            let res = await this.$store.dispatch('auth/identifierLogin', this.formData)
                .catch(this.preErrorHandler)
            this.loading = false

            if (!res)
                return

            this.$emit('login-completed')
        },

        async preErrorHandler(e) {
            if (e && e.response && e.response.status == 422) {
                if (e.response.data.type == 'use-social-login-instead') {
                    await this.$refs.alert.showError(this.$t('forms.LoginForm.use_social_login_instead'))
                    return
                }

                this.validationError = true
                return
            }

            this.errorHandler(e)
        },
    },
    watch: {
        loading(v) {
            this.$emit('update:loading', v)
        },
        validationError(v) {
            this.$emit('update:validationError', v)
        },
        isFormValid(v) {
            this.$emit('update:isFormValid', v)
        },
    },
}
</script>

<style scoped>
.router-link-default{
    color:#212121 !important;
}

.forgot-password {
    color: var(--v-primary-base);
    text-decoration: none;
}

</style>